export const pageCountMixin = {
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    getPageCount(pagination) {
      if (pagination) {
        this.count = pagination.itemsLength;
        return this.count;
      }
    },
  },
};
export default pageCountMixin;
